<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="4" class="flex-column d-flex">
                    <div class="mb-1">
                        <h6 class="mb-0">{{ $t('email') }}</h6>
                    </div>
                    <div class="border rounded equal-height p-4 mb-4 mb">
                        <ValidationProvider name="personal_email" rules="required|email" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('personal_email')">
                                <b-form-input v-model="formData.personal_email" type="email"
                                              :class="errors[0] ? 'is-invalid' : ''">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>
                <b-col md="4" class="flex-column d-flex">
                    <div class="mb-1">
                        <h6 class="mb-0">{{ $t('telephone') }}</h6>
                    </div>
                    <div class="border rounded equal-height p-4 mb-4 mb">
<!--                        <ValidationProvider name="mobile_tel_country_code" rules="required" v-slot="{ valid, errors }">-->
<!--                            <b-form-group :label="$t('country')">-->
<!--                                <multi-selectbox v-model="formData.mobile_tel_country_code" :options="countryCodeData"-->
<!--                                                 :multiple="false" :validateError="errors[0]" />-->
<!--                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>-->
<!--                            </b-form-group>-->
<!--                        </ValidationProvider>-->
                        <ValidationProvider name="mobile_tel" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="formData.registration_type_text==='Online'?$t('phone_number'):$t('gsm_no2')">
                                <PhoneNumberInput
                                    v-model="formData.mobile_tel"
                                    :default-country-code="countryCodeMobileTelFlag"
                                    :no-flag="!(studentProgram.legal_presence_type=='International' && studentProgram.registration_type_text=='Online')"
                                    :validate-error="errors[0]"
                                    @update="mobileTelUpdated"
                                ></PhoneNumberInput>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="home_tel" rules="" v-slot="{valid, errors}">
                            <b-form-group :label="$t('home_number')">
                                <PhoneNumberInput
                                    ref="phoneInput"
                                    v-model="formData.home_tel"
                                    :default-country-code="countryCodeHomeTelFlag"
                                    @update="otherPhoneUpdate"
                                    :validate-error="errors[0]"
                                ></PhoneNumberInput>
                            </b-form-group>
                        </ValidationProvider>
<!--                        <ValidationProvider name="mobile_tel" rules="required" v-slot="{ valid, errors }">-->
<!--                            <b-form-group :label="$t('mobile_number')">-->
<!--                                <b-form-input type="number"-->
<!--                                    v-model="formData.mobile_tel" :class="errors[0] ? 'is-invalid' : ''">-->
<!--                                </b-form-input>-->
<!--                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>-->
<!--                            </b-form-group>-->
<!--                        </ValidationProvider>-->
<!--                        <ValidationProvider name="home_tel" v-slot="{ valid, errors }">-->
<!--                            <b-form-group :label="$t('home_number')">-->
<!--                                <b-form-input v-model="formData.home_tel" v-phone-number-->
<!--                                              :class="errors[0] ? 'is-invalid' : ''">-->
<!--                                </b-form-input>-->
<!--                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>-->
<!--                            </b-form-group>-->
<!--                        </ValidationProvider>-->
                    </div>
                </b-col>
                <b-col md="4" class="flex-column d-flex">
                    <div class="mb-1">
                        <h6 class="mb-0">{{ $t('emergency_person') }}</h6>
                    </div>
                    <div class="border rounded equal-height p-4 mb-4 mb">
                        <ValidationProvider name="emergency_person_relation" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('degree_of_proximity')">
                                <b-form-input v-model="formData.emergency_person_relation" v-uppercase
                                              :class="errors[0] ? 'is-invalid' : ''">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="emergency_person_name" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('name') + ' ' + $t('surname')">
                                <b-form-input v-model="formData.emergency_person_name" v-uppercase
                                              :class="errors[0] ? 'is-invalid' : ''">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="emergency_person_phone" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('telephone')">
                                <PhoneNumberInput
                                    ref="phoneInput"
                                    v-model="formData.emergency_person_phone"
                                    :default-country-code="countryCodeEmergencyTelFlag"
                                    @update="emergencyPersonPhone"
                                    :validate-error="errors[0]"
                                ></PhoneNumberInput>

<!--                                <b-form-input v-model="formData.emergency_person_phone" v-phone-number-->
<!--                                              :class="errors[0] ? 'is-invalid' : ''">-->
<!--                                </b-form-input>-->
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="d-flex justify-content-center">
            <b-button variant="primary" @click="updateForm" :disabled="formProcess">
                {{ $t('save') }}
            </b-button>
        </div>


    </div>
</template>

<script>
// Services
import StudentProgramService from '@/services/StudentProgramService'

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

import handleInput from '@/helpers/handleInput';
import countryCodeInputData from "./countryCodeInputData"
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox';
import PhoneNumberInput from "@/components/elements/PhoneNumberInput.vue";

export default {
    components: {
        PhoneNumberInput,
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,

    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        }
    },
    created() {
        this.formId = this.studentProgram.id;
        this.getData()
    },
    data() {
        return {
            mask: '',
            formId: null,
            formData: {},
            formProcess: false,
            countryCodeInputData,
            registration_type_text:null,
            code:null,
            otherCode:null,

        }
    },
    computed: {
        countryCodeMobileTelFlag(){
            return this.getCountryCodeByCallingCode(this.formData.mobile_tel_country_code)
        },
        countryCodeHomeTelFlag(){
            return this.getCountryCodeByCallingCode(this.formData.home_tel_country_code)
        },
        countryCodeEmergencyTelFlag(){
            return this.getCountryCodeByCallingCode(this.formData.emergency_person_phone_country_code)
        }
    },
    methods: {
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                StudentProgramService.updateContact(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        if (response.data?.data?.message === "SMS_SEND") {
                            this.$emit('smsSendSuccess', `${this.formData.mobile_tel_country_code}${this.formData.mobile_tel}`);
                            return;
                        }
                        this.$toast.success("Information updated");
                    })
                    .catch((e) => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
                    .finally(() => {
                        this.formProcess = false
                    })

            }
        },
        handleInput,
        getCountryName(value) {
            const find = this.countryCodeInputData.find(a => a.value == value)
            if (find) {
                return find.text
            }
            return ""
        },
        async getData() {
            this.formData ={
                personal_email: this.studentProgram.personal_email,
                mobile_tel: this.studentProgram.mobile_tel,
                mobile_tel_country_code: this.studentProgram.mobile_tel_country_code,
                home_tel: this.studentProgram.home_tel,
                home_tel_country_code: this.studentProgram.home_tel_country_code,
                emergency_person_relation: this.studentProgram.emergency_person_relation,
                emergency_person_name: this.studentProgram.emergency_person_name,
                emergency_person_phone: this.studentProgram.emergency_person_phone,
                emergency_person_phone_country_code: this.studentProgram.emergency_person_phone_country_code,
                registration_type_text: this.studentProgram.registration_type_text,
            }
        },
        mobileTelUpdated(event){
            this.code=event
            this.formData.mobile_tel=this.code.nationalNumber;
            this.formData.mobile_tel_country_code='+'+this.code.countryCallingCode
        },
        otherPhoneUpdate(event){
            this.otherCode=event

            this.formData.home_tel=this.otherCode.nationalNumber;
            this.formData.home_tel_country_code='+'+this.otherCode.countryCallingCode
        },
        emergencyPersonPhone(event){
            this.formData.emergency_person_phone=event.nationalNumber
            this.formData.emergency_person_phone_country_code='+'+event.countryCallingCode
        }

    }
}
</script>
