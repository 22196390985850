<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"/>
	</div>
</template>

<script>
	import setSelected from "@/helpers/setSelected";
	import handleInput from "@/helpers/handleInput";

	import qs from "qs";
    import SessionService from "@/services/SessionService";

	export default {
		props: {
			value: {
				default: null
			},
			valueType: {
				default: 'module'
			},
			validateError: {
				type: String,
				default: ''
			},
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options);
			},
		},
		created() {
			this.getOptions();
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		methods: {
			setSelected: setSelected,
			handleInput: handleInput,
			getOptions() {
				this.options = [];
				const config = {
					params: {
						limit: -1
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}
				SessionService.getAll(config)
				                 .then(response => {
					                 const data = response.data.data;
					                 data.forEach((item) => {
						                 this.options.push({
							                 value: item.id,
							                 text: item.course_level_name +' '+ item.course_name + ' '+ `(${item.start_date} - ${item.end_date})`,
						                 });
					                 });
				                 })
				                 .then(() => {
					                 this.selected = this.setSelected(this.value, this.options);
					                 if (this.selected == null || this.selected.length == 0) {
						                 this.$emit("input", null)
					                 }
				                 })
			}
		}
	}
</script>
