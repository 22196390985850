<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('documents').toUpper() }}</h5>
        <div class="d-flex justify-content-end mb-3" v-if="checkPermission('student_upload')">
            <b-button variant="primary" @click="showCreateDocumentModal()">
                {{ $t('create_document') }}
            </b-button>
        </div>
        <hr v-show="loading" />
        <div class="position-relative">
            <loading v-show="loading" :description-show="true" />
            <div class="accordion" role="tablist" v-for="(value, key, index) in data" :key="index">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="'accordion-' + index" variant="default">{{ key }}</b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-' + index" visible role="tabpanel">
                        <b-card-body>
                            <b-table
                                bordered
                                striped
                                responsive
                                :items="value.items"
                            :fields="documentFields"
                            :empty-text="$t('there_are_no_records')"
                            show-empty
                            class="mb-3 table-dropdown no-scrollbar border rounded"
                            v-show="!loading"
                            >
                            <template #cell(date)="{ value }">
                                {{ value }}
                            </template>
                            <template #cell(type)="{ value }">
                                {{ value }}
                            </template>
                            <template #cell(description)="{ value }">
                                {{ value }}
                            </template>
                            <template #cell(buttons)="{ item }">
                                <b-button-group size="sm">
                                    <b-button
                                        v-if="checkPermission('student_download')"
                                        class="rounded"
                                        variant="outline-secondary"
                                        :long-request="true"
                                        @click="downloadFile(item)"
                                    >
                                        <i class="ri-search-2-line align-middle text-muted font-weight-bold"></i>
                                    </b-button>
                                    <b-button
                                        v-if="checkPermission('student_deletefile')"
                                        class="rounded ml-2"
                                        variant="outline-danger"
                                        :long-request="true"
                                        @click="documentDelete(item)"
                                    >
                                        <i class="ri-delete-bin-line"></i>
                                    </b-button>
                                </b-button-group>
                            </template>
                            </b-table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
        <CommonModal ref="modal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('documents_upload') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="row mb-5 align-items-center">
                    <div class="col-12">
                        <ValidationObserver ref="storeForm">
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('student_documents') }}</label>
                                            <parameter-selectbox v-model="form.type" code="student_document_types"
                                                sort="id" :validate-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="file" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('documents_upload')">
                                            <div class="d-flex custom-file-upload">
                                                <b-form-file v-model="form.file" :state="errors[0] ? false : null"
                                                    :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')"
                                                    ref="fileInput" multiple></b-form-file>
                                                <b-button variant="outline-secondary"
                                                    @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse')
                                                    }}</b-button>
                                            </div>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]">
                                            </div>
                                            <div class="alert alert-info mt-2">{{ $t('warning_file') }}</div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('explanation')">
                                            <b-input-group class="mb-1">
                                                <b-form-input v-model="form.explanation"
                                                    :state="errors[0] ? false : null" />
                                            </b-input-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                    <div class="col-12 mt-3 d-flex">
                        <b-button @click="fileUpload" type="button" variant="primary" class="btn-lg mr-2">
                            {{ $t('save') | toUpperCase }}
                        </b-button>
                    </div>
                </div>
            </template>
        </CommonModal>
        <!--<MissingDocument :student-program="this.studentProgram" />-->
    </div>
</template>
<script>
// Component
import Loading from '@/components/elements/Loading';
import CommonModal from "@/components/elements/CommonModal.vue";
// Service
import StudentProgramService from '@/services/StudentProgramService';
import i18n from "@/plugins/i18n";
import {EventBus} from "@/main";

export default {
    components: {
        CommonModal,
        Loading,
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            text: "12312312",
            data:null,
            form: {},
            documents: [],
            documentFields: [
                {
                    key: 'created_at',
                    label: this.toUpperCase('date'),
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'align-middle pd-1 width-110 text-center'
                },
                {
                    key: 'type',
                    label: this.toUpperCase('type'),
                    sortable: true,
                    tdClass: 'align-middle pd-1 '
                },
                {
                    key: 'description',
                    label: this.toUpperCase('explanation'),
                    sortable: true,
                    tdClass: 'align-middle pd-1 '
                },

                {
                    key: 'buttons',
                    label: '',
                    sortable: false,
                    tdClass: 'align-middle pd-1 width-100'
                }
            ],
            loading: false
        }
    },
    mounted() {
        this.getDocument()
    },
    methods: {

        formClear() {
            this.form = {
                type: null,
                file: null
            }
        },
        showCreateDocumentModal() {
            this.$refs.modal.$refs.commonModal.show()
        },
        changeData (data){
            const newObject = {}
            data.forEach((item)=>{
                if (!newObject[item.type]){
                    newObject[item.type]={
                        items:[]
                    }
                }
                newObject[item.type].items.push(item)
            })
            this.data=newObject

        },
        getDocument() {
            StudentProgramService.document(this.studentProgram.student_id)
                .then(response => {
                    const data =response.data.data
                    this.changeData(data)
                })
                .catch((e) => {
                    this.showErrors(e)
                })
        },
        downloadFile(item) {
            StudentProgramService.downloadFile(item.uuid)
                .then(response => {
                    if (response.headers['content-type'] === 'application/pdf') {
                        EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: item.file_name});
                    } else {
                        this._downloadFile(response, item.file_name)
                    }
                }).catch(err => {
                    this.showErrors(err)
                })
        },
        documentDelete(item) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        StudentProgramService.documentDelete(item.uuid)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getDocument();
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
        async fileUpload() {
            const valid = await this.$refs.storeForm.validate();
            if (!valid) return
            const formData = new FormData();
            formData.append("type", this.form.type);
            formData.append("explanation", this.form.explanation);
            formData.append("file", this.form.file[0]);
            formData.append("student_id", this.studentProgram.student_id);
            formData.append("student_program_id", this.studentProgram.id);
            StudentProgramService.fileUpload(formData)
                .then(response => {
                    this.$toast.success(response.data.message)
                    this.$refs.modal.$refs.commonModal.hide()
                    this.getDocument()
                    this.form = {};
                })
                .catch((e) => {
                    this.showErrors(e)
                })
        }

    }
}
</script>
