<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('sessions').toUpper() }}</h5>
        <div class="d-flex justify-content-end mb-3" v-if="checkPermission('studentsession_store')">
            <b-button variant="primary" @click="showCreate()">
                {{ $t('create_session') }}
            </b-button>
        </div>
        <hr v-show="loading"/>
        <div class="position-relative">
            <loading v-show="loading" :description-show="true"/>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
        </div>
        <CommonModal ref="modal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                <div>{{ formProcess === 'createForm' ? $t('create') : $t('update') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <CreateForm v-if="formProcess==='createForm'" @successCreate="successCreate"
                            :studentProgram="studentProgram"/>
                <UpdateForm v-if="formProcess==='updateForm'" :studentProgram="studentProgram"
                            @successCreate="successCreate" :formId="formId"/>
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Component
import Loading from '@/components/elements/Loading';
import CommonModal from "@/components/elements/CommonModal.vue";
// Service

import StudentSessionService from "@/services/StudentSessionService";
import CreateForm from "@/modules/students/pages/student/sessions/CreateForm.vue";
import UpdateForm from "@/modules/students/pages/student/sessions/UpdateForm.vue"
import qs from "qs";

export default {
    components: {
        CreateForm,
        CommonModal,
        Loading,
        UpdateForm
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            // {
                            //     text: this.$t("edit"),
                            //     class: "ri-edit-box-line",
                            //     permission: "studentsession_update",
                            //     callback: (row) => {
                            //         this.showUpdate(row.id);
                            //     },
                            // },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "studentsession_delete",
                                callback: (row) => {
                                    this.deleteForm(row.id);
                                },
                            },
                        ],
                    },

                    {
                        label: this.$t("course_name"),
                        field: 'esl_course_name',

                    },
                    {
                        label: this.$t("level"),
                        field: "level_name",

                    },
                    {
                        label: this.$t("start_date"),
                        field: "session.start_date",

                    },
                    {
                        label: this.$t("end_date"),
                        field: "session.end_date",

                    },
                    {
                        label: this.$t("instructor"),
                        field: "instructor_name",
                    },
                    {
                        label: this.$t("score"),
                        field: "score",
                    },
                    {
                        label: this.$t("absences"),
                        field: "absences",
                    },
                    {
                        label: this.$t("passed"),
                        field: "passed",
                    },
                    {
                        label: this.$t("conditionally_passed"),
                        field: "conditionally_passed",
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        student_program_id: this.studentProgram.id,
                    },
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
            loading: false,
            formId: null,
        }
    },
    mounted() {
        this.getAll()
    },
    methods: {
        formClear() {
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },

        showCreate() {
            this.formProcess = 'createForm';
            this.$refs.modal.$refs.commonModal.show()
        },
        showUpdate(id) {
            this.formProcess = 'updateForm';
            this.formId = id
            this.$refs.modal.$refs.commonModal.show()
        },
        async getAll() {
            this.datatable.showTable = true
            this.datatable.isLoading = true
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            StudentSessionService.getAll(config)
                .then(response => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total
                })
                .catch((e) => {
                    this.showErrors(e)
                }).finally(this.datatable.isLoading = false)
        },
        deleteForm(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        StudentSessionService.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message))
                                this.getAll()
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message))
                            })
                    }
                })
        },
        async successCreate() {
            await this.getAll()
            this.formProcess = null
            this.$refs.modal.$refs.commonModal.hide()

        },
    }
}
</script>
