<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <ValidationObserver ref="storeForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="session_id" rules="required" v-slot="{ valid, errors }">
                            <b-form-group>
                                <label>{{ $t('sessions') }}</label>
                                <SessionsSelectbox v-model="form.session_id" :validate-error="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="score" rules="min_value:0|max_value:100" v-slot="{valid, errors}">
                            <b-form-group :label="$t('score')">
                                <b-form-input type="number"
                                              :min="0"
                                              :max="100"
                                              @input="handleChange"
                                              v-model="form.score"
                                              :state="errors[0] ? false : (valid ? true : null)">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="absences" rules="min_value:0|max_value:100" v-slot="{valid, errors}">
                            <b-form-group :label="$t('absences')">
                                <b-form-input type="number"
                                              :min="0"
                                              :max="100"
                                              @input="handleChangeAbsences"
                                              v-model="form.absences"
                                              :state="errors[0] ? false : (valid ? true : null)">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="passed" rules="" v-slot="{valid, errors}">
                            <b-form-group :label="$t('passed')">
                                <yes-no-selectbox :is-boolean="true" v-model="form.passed">
                                </yes-no-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>

                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="conditionally_passed" rules="" v-slot="{valid, errors}">
                            <b-form-group :label="$t('conditionally_passed')">
                                <yes-no-selectbox
                                    :is-boolean="true"
                                    v-model="form.conditionally_passed">
                                </yes-no-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>

                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="comment" rules="" v-slot="{valid, errors}">
                            <b-form-group  :label="$t('comment')">
                                <b-form-textarea v-model="form.comment"
                                                 :class="errors[0] ? 'is-invalid':''"
                                                 rows="5"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="save" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t('save') | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>
<script>
import StudentSessionService from "@/services/StudentSessionService";
import showErrors from "@/helpers/showErrors";
import SessionsSelectbox from "@/components/interactive-fields/SessionsSelectbox.vue";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox.vue";
export default {
    props:{
        studentProgram:{
            type: Object,
        },
        formId:{
            type: Number,
        }
    },
    components: {YesNoSelectbox, SessionsSelectbox},
    data(){
        return {
            form : {
                session_id: null,
                score:null,
                absences:null,
                comment:null,
                passed:null,
                conditionally_passed:null,
            }
        }
    },
    watch:{
        formId:{
            handler(newVal){
                this.getSession(newVal)

            }
        }
    },
    created() {
      this.getSession(this.formId)
    },
    methods: {
        handleChange(value){
            const number = Number(value);
            if (number>100){
                this.form.score = 100;
            }else {
                this.form.score = number;
            }
        },
        handleChangeAbsences(value){
            const number = Number(value);
            if (number>100){
                this.form.absences = 100;
            }else {
                this.form.absences = number;
            }
        },
        getSession(id){
            StudentSessionService.show(id)
                .then(result => {
                    this.form=result.data.data
                }).catch(error => {
                    this.showErrors(error)
            })

        },
        async save(){
            const isValid =await this.$refs.storeForm.validate()
            if (!isValid) return
            this.form.student_program_id=this.studentProgram.id
            StudentSessionService.update(this.formId,this.form)
                .then(response => {
                    this.$toast.success(this.$t("api." + response.data.message));
                    this.$emit('successCreate',true)
                }).catch(err=>{
                showErrors(err,this.$refs.storeForm)
            })
        }
    }
}
</script>
