<template>
    <div>
        <div class="border rounded p-3 p-xxl-4 mb-2 overflow-y-scroll overflow-x-hidden position-sticky profile-image">
            <div class="row">
                <div class="col-5 col-lg-12">
                    <div class="embed-responsive embed-responsive-1by1 rounded mb-4 d-flex justify-content-center align-items-center text-muted bg-cover-center bg-gray-50"
                        :style="{backgroundImage: photo ? 'url(' + photo + ')':''}">
                        <i class="ri-user-4-line ri-3x mb-3" v-if="!photo"/>
                        <div class="d-flex justify-content-between position-absolute bottom-0 w-100 p-2"
                             v-if="editMode">
                            <button class="btn btn-xs font-weight-normal bg-gray-50 rounded"
                                    @click="uploadPhotoModalShow">
                                <i class="ri-upload-2-line font-size-base"></i>
                            </button>
                            <button class="btn btn-xs font-weight-normal bg-gray-50 rounded text-danger">
                                <i class="ri-delete-bin-line font-size-base"></i>
                            </button>
                        </div>
                        <div class="spinner over bg-white-50 d-none">
                            <b-spinner small label="spinning" class="text-primary"></b-spinner>
                        </div>
                    </div>
                    <!--
                    <div class="d-flex justify-content-between mb-5" v-if="editMode">
                        <a href="javascript:;" class="small text-uppercase">
                            <i class="ri-mail-line mr-2 top-plus-1"></i>
                            {{ $t('email') }}
                        </a>
                        <a href="javascript:;" class="small text-uppercase text-body">
                            <i class="ri-chat-4-line flip-x mr-2 top-plus-1"></i>
                            {{ $t('message') }}
                        </a>
                    </div>
                    -->
                </div>
                <div class="col-7 col-lg-12">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">
                                    {{ $t('student_number') }}
                                </label>
                                {{ studentProgram.student_number }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">
                                    {{ $t('name') + ' ' + $t('surname') }}
                                </label>
                                {{ studentProgram.name ? studentProgram.name : ' - ' }}
                                {{ studentProgram.surname ? studentProgram.surname : ' - ' }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">{{ $t('mobile_number') }}</label>
                                {{ studentProgram.mobile_tel ? studentProgram.mobile_tel : ' - ' }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">{{ $t('registration_type') }}</label>
                                {{ studentProgram.registration_type_text ? studentProgram.registration_type_text : '-' }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">{{ $t('program_name') }}</label>
                                {{ studentProgram.program_name || "-" }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">{{ $t('program_code') }}</label>
                                {{ studentProgram.program_code }}
                            </b-form-group>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">{{ $t('scholarship_rate') }}</label>
                                <label v-if="studentProgram.scholarship_rate==null">{{ $t("scholor_ship_rate_ration")}}</label>
                                <label v-else>{{ 'ÖSYM %' + studentProgram.scholarship_rate }}</label>
                            </b-form-group>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 d-block">{{ $t('classrate') }}</label>
                                <span v-if="studentProgram && studentProgram.success_rate">
                                    {{ '%' + studentProgram.success_rate.toFixed(2) }}
                                </span>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12 mb-0">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 mr-1">Financial Hold</label>
                                <b-badge variant="success text-uppercase" v-if="studentProgram.payment_status=='No'">
									<span class="d-flex align-items-center">
										<i class="ri-check-line mr-1 top-minus-1 font-size-base"></i>No
									</span>
                                </b-badge>
                                <b-badge variant="danger text-uppercase" v-else>
									<span class="d-flex align-items-center">
										<i class="ri-close-line mr-1 top-minus-1 font-size-base"></i>Yes
									</span>
                                </b-badge>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12 mb-0">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 mr-1">Missing Document Hold</label>
                                <b-badge variant="danger text-uppercase" v-if="studentProgram && studentProgram.holds.includes('Missing Document Hold')">
									<span class="d-flex align-items-center">
										<i class="ri-close-line mr-1 top-minus-1 font-size-base"></i>Yes
									</span>
                                </b-badge>
                                <b-badge variant="success text-uppercase" v-else>
									<span class="d-flex align-items-center">
										<i class="ri-check-line mr-1 top-minus-1 font-size-base"></i>No
									</span>
                                </b-badge>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-sm-6 col-md-12 mb-0">
                            <b-form-group class="mb-2">
                                <label class="col-form-label p-0 pb-1 mr-1">Survey Hold</label>
                                <b-badge variant="danger text-uppercase" v-if="studentProgram && studentProgram.holds.includes('Survey Hold')">
									<span class="d-flex align-items-center">
										<i class="ri-close-line mr-1 top-minus-1 font-size-base"></i>Yes
									</span>
                                </b-badge>
                                <b-badge variant="success text-uppercase" v-else>
									<span class="d-flex align-items-center">
										<i class="ri-check-line mr-1 top-minus-1 font-size-base"></i>No
									</span>
                                </b-badge>
                            </b-form-group>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <CommonModal ref="uploadPhotoModal" :onHideOnlyX="true" v-if="editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('image_upload').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <b-form-group :label="$t('photo')" class="mb-2">
                    <div class="d-flex custom-file-upload mb-2">
                        <b-form-file
                            v-model="photoFile"
                            :placeholder="$t('select_file')"
                            :drop-placeholder="$t('drop_file')"
                            ref="fileInput"
                            accept="image/jpeg, image/png, image/gif"
                        >
                        </b-form-file>
                        <b-button v-if="hasPhoto" variant="danger" @click="clearPhoto">
                            <i class="ri-close-fill"></i>
                        </b-button>
                        <b-button v-else variant="outline-secondary"
                                  @click="$refs.fileInput.$el.childNodes[0].click();">
                            <i class="ri-add-fill"></i>
                        </b-button>
                    </div>
                    <b-img v-if="hasPhoto" :src="photoFileSrc" fluid block rounded></b-img>
                </b-form-group>
                <b-button variant="primary" block v-if="hasPhoto" @click="uploadPhoto">{{ $t('upload') }}</b-button>
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Components
    import CommonModal from '@/components/elements/CommonModal';

    // Helper
    import toBase64 from '@/helpers/toBase64'

    // Services
    import StudentProgramService from '@/services/StudentProgramService';



    export default {
        components: {
            CommonModal
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            studentProgram: {
                handler(value) {
                    this.getPhoto()
                },
                immediate: true
            },
            photoFile(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        this.base64Encode(newValue)
                            .then(value => {
                                this.photoFileSrc = value;
                            })
                            .catch(() => {
                                this.photoFileSrc = null;
                            });
                    }
                    else {
                        this.photoFileSrc = null;
                    }
                }
            }
        },
        data() {
            return {
                hi: false,
                photoFile: null,
                photoFileSrc: null,
                photo: null,
                photoUploadProcess: false
            }
        },
        created() {
            this.getPhoto();
        },
        computed: {
            hasPhoto() {
                return !!this.photoFile;
            }
        },
        methods: {
            toBase64: toBase64,
            getPhoto() {
                if (typeof this.studentProgram.id != 'undefined') {
                    StudentProgramService.photo(this.studentProgram.id)
                                         .then(response => {
                                             const base64 = this.toBase64(response);
                                             if(base64.length<100){
                                                 this.photo = null;
                                             }
                                             else {
                                                 this.photo = base64;
                                             }
                                         })
                                         .catch(e => {
                                             this.photo = null;
                                         })
                }
            },

            uploadPhotoModalShow() {
                this.$refs.uploadPhotoModal.$refs.commonModal.show()
            },
            uploadPhotoModalHide() {
                this.$refs.uploadPhotoModal.$refs.commonModal.hide()
            },
            clearPhoto() {
                this.photoFile = null;
            },
            uploadPhoto() {
                if (!this.photoFile) {
                    this.$toast.error(this.$t('api.' + response.data.message));
                }
                else {
                    let formData = new FormData();
                    formData.append('photo', this.photoFile)
                    StudentProgramService.uploadPhoto(this.studentProgram.id, formData)
                                         .then(response => {
                                             this.$toast.success(this.$t('api.' + response.data.message));
                                         })
                                         .catch(e => {
                                             this.showErrors(e);
                                         })
                                         .finally(() => {
                                             this.photoFile = null;
                                             this.getPhoto();
                                             this.uploadPhotoModalHide()
                                         })
                }
            }
        }
    }
</script>
<style scoped>
    .profile-image {
        width: 100%;
    }

    @media screen and (min-width: 992px) {
        .profile-image {
            width: 200px
        }
    }

    @media screen and (min-width: 1281px) {
        .profile-image {
            width: 250px
        }
    }
</style>
