<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <ValidationObserver ref="storeForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="session_id" rules="required" v-slot="{ valid, errors }">
                            <b-form-group>
                                <label>{{ $t('sessions') }}</label>
                                <SessionsSelectbox v-model="form.session_id" :validate-error="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="save" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t('save') | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>
<script>
import StudentSessionService from "@/services/StudentSessionService";
import showErrors from "@/helpers/showErrors";
import SessionsSelectbox from "@/components/interactive-fields/SessionsSelectbox.vue";
export default {
    props:{
        studentProgram:{
            type: Object,
        }
    },
    components: {SessionsSelectbox},
    data(){
        return {
            form : {
                type: null,
                file: null
            }
        }
    },

    methods: {
        async save(){
            const isValid =await this.$refs.storeForm.validate()
            if (!isValid) return
            this.form.student_program_id=this.studentProgram.id
            StudentSessionService.store(this.form)
                .then(response => {
                    this.$toast.success(this.$t("api." + response.data.message));
                    this.$emit('successCreate',true)
                }).catch(err=>{
                showErrors(err,this.$refs.storeForm)
            })
        }
    }
}
</script>
