<template>
  <div class="vue-phone-number-input" :class="{ 'no-flag': noFlag }">
    <vue-phone-number-input v-model="phone"
                            :no-country-selector="noCountrySelector"
                            :no-example="noExample"
                            :default-country-code="defaultCountryCode"
                            :translations="translations"
                            :disabled="disabled"
                            :no-validator-state="true"
                            :error="validateError ? true:false"
                            :error-color="'#ff9eae'"
                            :prepend-country-code="true"
                            @update="handleUpdate"
    />
    <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      type: String,
      default: null
    },

    onlyCountries: {
      type: Array,
      default: null
    },
    noCountrySelector: {
      type: Boolean,
      default: false
    },
    noExample: {
      type: Boolean,
      default: false
    },
    defaultCountryCode: {
      type: String,
      default: 'US'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validateError: {
      type: String,
      default: ''
    },
      noFlag: {
          type: Boolean,
          default: false,
      },

  },
    created() {
        this.phone = this.value
    },
    watch: {
        value(val) {
            this.phone = val
        },
    },
  data() {
    return {
      phone: null,
      translations: {
        countrySelectorLabel: '',
        countrySelectorError: '',
        phoneNumberLabel: '',
        example: '',
      },
    }
  },
  methods: {
    handleUpdate(event){
        this.$emit('input', event.formattedNumber)
        this.$emit('update', event)
    },
  }
}
</script>

<style>
.vue-phone-number-input .input-tel__input {
  border: 1px solid #e9ecef;
  padding: 12px;
}

.vue-phone-number-input .input-tel__label {
  display: none;
}

.vue-phone-number-input .input-tel.has-hint .input-tel__input, .vue-phone-number-input .input-tel.has-value .input-tel__input {
  padding-top: 12px;
}

.vue-phone-number-input .input-tel.is-disabled .input-tel__input {
  border: 1px solid #e9ecef;
  background-color: #f5f6f7;
  color: #343a40;
}

.vue-phone-number-input.no-flag .country-selector.has-value .country-selector__input[data-v-46e105de] {
    padding-left: 40px;
    pointer-events: none;
    cursor: default;
}

.vue-phone-number-input.no-flag .country-selector__toggle[data-v-46e105de] {
    position: absolute;
    right: 5px;
    top: calc(50% - 10px);
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    display: inline-block;
    height: 24px;
    pointer-events: none;
    cursor: not-allowed;
}
.country-selector__country-flag[data-v-46e105de] {
    margin: auto 0;
    position: absolute;
    top: 21px;
    left: 11px;
    z-index: 1;
    cursor: none;
    pointer-events: none;
}

</style>
